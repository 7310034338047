import axios from 'axios';

// Axios 인스턴스 생성
const api = axios.create({
  baseURL: 'http://dytax01n.dytaxgroup.com/api',
});

// 요청 인터셉터
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        config.headers['Authorization'] = `ASMMAKE ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const msid = localStorage.getItem('msId');
        const response = await axios.post('http://dytax01n.dytaxgroup.com/api/mb/dytaxrefreshtoken', { msid,refreshToken });

        if (response.status === 200) {
          const { accessToken } = response.data;
          localStorage.setItem('access_token', response.data);
          originalRequest.headers['Authorization'] = `ASMMAKE ${response.data}`;
          return axios(originalRequest);
        }
      } catch (refreshError) {
        console.error('Refresh token failed:', refreshError);
        //토큰이 만료되었으니 토큰을 초기화 한다.
         localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        // 리프레시 토큰이 만료되었거나 실패한 경우 로그인 페이지로 리디렉션
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default api;
